@import '~src/less/definitions';

a[href] {
    color: @color-primary;
    text-decoration: none;
    &:hover, &:active {
        text-decoration: underline;
    }
}

@font-face {
    font-family: 'cjk';
    src: local('Microsoft YaHei'), local('Apple LiGothic Medium'), local('AR PL UMing'), local('monospace');
    unicode-range: U+3000-30FF, U+FF00-FFEF, U+4E00-9FAF;
}

:global(#app) {
    min-height: 100vh;
    display: flex;
    flex-flow: column nowrap;

    font-family: cjk, Corbel, "Lucida Grande", "Lucida Sans Unicode", "Lucida Sans", "DejaVu Sans", "Bitstream Vera Sans", "Liberation Sans", Verdana, "Verdana Ref", sans-serif;
    font-weight: 400;
    font-size: @font-size;
    line-height: @line-height;

    background-color: @color-bg;
    color: @color-text;

    > * {
        flex: 1;
        display: inherit;
        flex-flow: column nowrap;
    }

    .container {
        flex: 1;
        display: flex;
        flex-flow: column nowrap;

        width: 100%;
        max-width: @width-container-max;

        margin: (@height-header + 2 * @spacing-y) auto @spacing-y;
        padding: 0 @spacing-x;
        box-sizing: border-box;
    }
}

