@spacing-x: 8px;
@spacing-y: 8px;

@width-sm: 520px;
@width-md: 768px;

@width-container-max: @width-md;

.media-screen-sm(@rules) {
    @media screen and (max-width: @width-sm) {
        @rules();
    }
}

@font-size: 16px;
@line-height: 1.6;

@duration-transition: 0.2s;
@duration-enter-exit: 0.3s;

@color-primary: #800000;
@color-bg: white;
@color-text-dark: rgb(10%, 10%, 10%);
@color-text-light: rgb(90%, 90%, 90%);

@color-text: contrast(
    @color-bg, @color-text-dark, @color-text-light
);

@color-bg-hover: tint(@color-primary, 25%);
@color-bg-active: @color-primary;
@color-bg-card: lighten(@color-bg, 5%);

@color-text-bg-primary: contrast(
    @color-primary, @color-text-dark, @color-text-light
);

@color-text-bg-hover: contrast(
    @color-bg-hover, @color-text-dark, @color-text-light
);

@color-text-bg-active: contrast(
    @color-bg-active, @color-text-dark, @color-text-light
);

@height-header: 2 * @spacing-y + @line-height * @font-size;
@box-shadow: 0 (@spacing-y / 2) (@spacing-y / 2) fadeout(@color-text, 75%);
@border: 1px solid fadeout(@color-text, 75%);

@z-index-header: 100;
@z-index-modal: 1000;

