@import '~src/less/definitions';

.footer {
    display: flex;
    flex-flow: column nowrap;
    font-size: smaller;

    border-top: @border;

    nav {
        flex: 1;
        display: flex;
        flex-flow: row wrap;

        width: 100%;
        max-width: @width-container-max;
        margin: @spacing-y auto;
        box-sizing: border-box;

        .left, .right {
            display: flex;
            flex-flow: row wrap;
            justify-content: flex-end;

            > * {
                padding: 0 @spacing-x;
            }
        }

        .right {
            margin-left: auto;
        }
    }
}

