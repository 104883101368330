@import '~src/less/definitions';

.active() {
    background-color: @color-bg-active;
    color: @color-text-bg-active;
}

.hover() {
    background-color: @color-bg-hover;
    color: @color-text-bg-hover;
    text-decoration: none;
}

.logo {
    display: flex;
    flex-flow: row nowrap;

    @line-height: 1.1;
    line-height: @line-height;

    .image {
        @height: 2 * @font-size * @line-height;
        height: @height;
        min-width: @height;
        background-image: url('images/logo.png');
        background-size: cover;
        background-position: cover;
        transition: background-image @duration-transition ease-in-out;
    }

    .text {
        display: flex;
        flex-flow: column;

        margin-left: @spacing-x;
        text-align: center;

        > .zh {
            font-size: smaller;
        }
    }
}

.header {
    position: fixed;
    width: 100%;
    z-index: @z-index-header;

    box-shadow: @box-shadow;
    background-color: @color-bg;
    font-size: larger;

    a[href], .dropdown .button, > .toggle {
        display: flex;
        align-items: center;
        justify-content: center;

        padding: @spacing-y @spacing-x;

        color: @color-primary;
        background-color: @color-bg;
        transition: color @duration-transition;
        transition: background-color @duration-transition;
        text-decoration: none;

        cursor: pointer;

        &:hover, &:focus {
            .hover();
        }

        &:active, &.active, &.open {
            .active();
        }
    }

    a[href].active {
        .active();
    }

    > .toggle {
        display: none;
    }

    > nav {
        display: flex;
        flex-flow: row wrap;

        width: 100%;
        max-width: @width-container-max;
        margin: 0 auto;
        box-sizing: border-box;

        background-color: @color-bg;

        .dropdown {
            position: relative;
            display: flex;
            flex-flow: column;

            .button {
                height: 100%;
                box-sizing: border-box;

                position: relative;

                &:after {
                    @size: (@font-size / 2);

                    margin-left: (@size / 2);
                    width: 0;
                    height: 0;
                    content: ' ';

                    color: inherit;
                    border-top: (@size / 2) solid transparent;
                    border-left: @size solid;
                    border-bottom: (@size / 2) solid transparent;

                    opacity: 0.5;
                    transition-property: opacity, transform;
                    transition-duration: @duration-transition;
                }

                &:hover, &:focus, &:active, &.open {
                    &:after {
                        opacity: 1;
                    }
                }

                &.open:after {
                    transform: rotate(90deg);
                }
            }

            .menu {
                display: flex;
                flex-flow: column nowrap;
                align-items: stretch;
                white-space: nowrap;
                min-width: 100%;

                z-index: @z-index-modal;
                position: absolute;
                top: 100%;
                left: 0;

                background-color: @color-bg;
                color: @color-text;
                box-shadow: @box-shadow;

                transform-origin: top center;

                transition-property: opacity, transform;
                transition-duration: @duration-enter-exit;

                .media-screen-sm({
                    width: 100%;
                });

                &.enter, &.exit.exitActive {
                    transform: rotateX(90deg);
                    opacity: 0;
                }

                &.exit, &.enter.enterActive {
                    transform: none;
                    opacity: 1;
                }
            }
        }
    }

    .media-screen-sm({
        background: none;

        display: flex;
        flex-flow: column nowrap;

        > .toggle {
            display: unset;
            justify-content: flex-start;

            line-height: 1;
            font-size: @line-height * @font-size;

            &:hover, &:focus {
                .hover();
            }

            &:active {
                .active();
            }

            &:before {
                content: '≡';
                padding-right: @spacing-x;
            }
        }

        > nav {
            flex-flow: column nowrap;

            position: absolute;
            top: @height-header;

            transform-origin: top center;
            transform: rotateX(90deg);

            opacity: 0;

            transition-property: opacity, transform;
            transition-duration: @duration-transition;

            box-shadow: @box-shadow;
        }

        &.open {
            height: 100vh;

            > .toggle {
                .active();

                &:before {
                    content: '×';
                }
            }

            > nav {
                transform: none;
                opacity: 1;
            }
        }
    });
}

